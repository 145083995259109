import { EntityType } from "../enums/entity-type.enum";
import { ACLDto } from "./acl.dto";
import { BaseDto } from "./base.dto";
import {Role} from "../enums/role.enum";

export class PermissionDto extends BaseDto{
    entityType: EntityType;
    refId:number;
    parentId:number;
    inherited:boolean;
    ownerId:number;
    acls:ACLDto[] = [];
    role?: Role
}
