<div *ngIf="currentSpace" style="overflow: hidden; display: flex; flex-flow: column; height: 100%">
  <div
    class="default-title"
    *ngIf="defaultSpace.id != currentSpace.id && !showAllSpaces"
    [style.justify-content]="showTitles ? 'space-between' : 'center'"

    (click)="showAllSpaces = false; changeCurrentSpace(defaultSpace.id)"
  >
    <div class="item-left">
      <inline-title
        [icon]="defaultSpace.icon"
        [hideText]="!showTitles"
        size="xlarge"
        [title]="defaultSpace.name"
        [editable]="false"
        (submit)="onRename($event)"
        [ngbTooltip]="showTitles ? '' : currentSpace.name"
        container="body"
        color="white"
      ></inline-title>
    </div>
  </div>

  <div
    *ngIf="!showAllSpaces"
    [hidden]="!showTitles"
    class="space-title"
    #dropdown="ngbDropdown"
    ngbDropdown
    container="body"
    placement="bottom-end"
    [style.justify-content]="showTitles ? 'space-between' : 'center'"
  >
    <div class="item-left" (contextmenu)="openContextMenu(dropdown)">
      <inline-title
        [icon]="currentSpace.icon"
        [hideText]="!showTitles"
        size="xxlarge"
        [title]="currentSpace.name"
        [editable]="currentRenaming"
        (submit)="onRename($event)"
        [ngbTooltip]="showTitles ? '' : currentSpace.name"
        container="body"
        color="orange"
      ></inline-title>
    </div>
    <div class="item-right" ngbDropdownAnchor [hidden]="!showTitles || currentRenaming">
      <icon
        icon="bi-journal-plus"
        size="large"
        (click)="onCreateSheetClick(); $event.stopPropagation()"
        color="naviblue-lighter"
        ngbTooltip="{{'ADD_NEW_WORKSHEET' | translate }}"
        placement="bottom-end"
        [openDelay]="500"
      ></icon>

      <icon
        icon="bi-folder-plus"
        size="large"
        ngbTooltip="{{'NEW_FOLDER' | translate }}"
        placement="bottom-end"
        [openDelay]="500"
        color="naviblue-lighter"
        (click)="onCreateFolderClick(); $event.stopPropagation()"
      ></icon>

      <icon
        icon="bi-three-dots-vertical"
        color="naviblue-lighter"
        size="large"
        (click)="dropdown.open(); $event.stopPropagation()"
      ></icon>
    </div>
    <div ngbDropdownMenu>
      <div
        *ngIf="!currentDeleting"
        class="dropdownitem"
        (click)="onCreateTaskSheetClick(); dropdown.close()"
      >
        <icon icon="bi-list-task" color="naviblue"></icon>
        {{ "ADD_NEW_TASKSHEET" | translate }}
      </div>
      <div
        *ngIf="!currentDeleting"
        class="dropdownitem"
        (click)="currentRenaming = true; dropdown.close()"
      >
        <icon icon="bi-pencil" color="naviblue"></icon>
        {{ "RENAME" | translate }}
      </div>
      <div
        *ngIf="!currentDeleting"
        class="dropdownitem"
        (click)="showPermissionDialog(); dropdown.close()"
      >
        <icon icon="bi-shield-lock" color="naviblue"></icon>
        {{ "PERMISSIONS" | translate }}
      </div>
      <div *ngIf="!currentDeleting" class="dropdownitem" (click)="currentDeleting = true">
        <icon icon="bi-folder-x" color="naviblue"></icon>
        {{ "delete" | translate }}
      </div>
      <div *ngIf="currentDeleting" class="confirm-delete-holder">
        <div class="confirm-title">{{ "areYouSure?" | translate }}</div>
        <div class="confirm-delete-button-holder">
          <span
            class="confirm-yes"
            (click)="currentDeleting = false; dropdown.close(); deleteCurrentSpace()"
          >{{ "delete" | translate }}</span
          >
          <span class="confirm-no" (click)="currentDeleting = false; dropdown.close()">{{
              "cancel" | translate
            }}</span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!showAllSpaces" class="space-holder">
    <app-tree-folder
      class="space-items"
      [showTitles]="showTitles"
      [folder]="tree"
      [currentItem]="activeSheetId"
      [currentSpaceId]="currentSpace.id"
    ></app-tree-folder>

    <div class="create-item-holder" [hidden]="!showTitles">
      <div class="create-item-title" (click)="createExpanded = !createExpanded">
        {{ "CREATE_NEW_ITEM" | translate }}
        <icon
          icon="bi-chevron-down"
          class="create-item-anchor"
          [ngClass]="createExpanded ? 'create-item-anchor-rotate' : ''"
        ></icon>
      </div>
      <div class="create-item-list" [ngClass]="createExpanded ? 'create-item-list-active' : ''">
        <div
          class="create-item-list-item"
          (click)="createExpanded = !createExpanded; onCreateFolderClick()"
        >
          <icon icon="bi-folder-plus"></icon>
          <span> {{ "NEW_FOLDER" | translate }} </span>
        </div>

        <div
          class="create-item-list-item"
          (click)="createExpanded = !createExpanded; onCreateSheetClick()"
        >
          <icon icon="bi-journal-plus"></icon>
          <span> {{ "NEW_WORKSHEET" | translate }} </span>
        </div>

        <div
          class="create-item-list-item"
          (click)="createExpanded = !createExpanded; onCreateTaskSheetClick()"
        >
          <icon icon="bi-list-task"></icon>
          <span> {{ "NEW_TASKSHEET" | translate }} </span>
        </div>
        <div
          class="create-item-list-item"
          (click)="createExpanded = !createExpanded; onCreatePageClick()"
        >
          <icon icon="bi-blockquote-left"></icon>
          <span> {{ "new_page" | translate }} </span>
        </div>

        <div
          class="create-item-list-item"
          (click)="createExpanded = !createExpanded;importCsvModal()"
        >
          <icon icon="bi-file-earmark-arrow-down"></icon>
          <span> {{ "IMPORT_CSV" | translate }} </span>
        </div>
        <div
          class="create-item-list-item"
          (click)="createExpanded = !createExpanded;openMarketplace()"
        >
          <icon icon="bi-shop-window"></icon>
          <span> {{ "IMPORT_TEMPLATE" | translate }} </span>
        </div>
      </div>
    </div>
  </div>

  <div class="rest-spaces" [hidden]="!showTitles" [class.rest-spaces-all]="showAllSpaces">
    <div class="all-spaces-title">
      <span style="display:flex"><b>{{ "workspaces" | translate }}</b>
      <icon icon="bi-plus" style="margin-inline-start: 4px" size="xlarge" [hoverable]="true" (click)="onCreateSpaceClick()"></icon>
      </span>
      @if (spaces.length > 3 && !showAllSpaces) {
        <span (click)="showSpacesDialog()"
              class="show-all-button">{{ "show_all" | translate }}</span>
      }
    </div>
    <div *ngFor="let space of spaces.slice(0, showAllSpaces ? spaces.length : 4)">
      <div
        class="rest-spaces-item"
        *ngIf="(space.id != currentSpace.id && space.id != defaultSpace.id)|| showAllSpaces"
        (click)="showAllSpaces = false; changeCurrentSpace(space.id)"
      >
        <icon [icon]="space.icon" size="large"></icon>
        <span
          style=" text-wrap: nowrap; overflow: hidden; text-overflow: ellipsis">
          {{ space.name }}
        </span>
      </div>
    </div>

    <div *ngIf="showAllSpaces || spaces.length <4" (click)="onCreateSpaceClick()"
         class="create-space">
      <icon icon="bi-journals" color="white" size="xlarge"></icon>
      <span style="margin-left: 8px"> {{ "CREATE_NEW_SPACE" | translate }} </span>
    </div>
  </div>
</div>
