<div class="setting-menu">
  <!-- <div class="title" [ngClass]="showTitles?'':'justify'"> -->
  <!-- <icon icon="bi-gear" color="orange" size="xxlarge"></icon> -->
  <!-- <span [hidden]="!showTitles">Administration</span> -->
  <!-- </div> -->
  <div class="menu-item" [ngClass]="showTitles?'':'justify'"
       routerLink="/panel/setting/organization" routerLinkActive="active">
    <icon icon="bi-building" size="xlarge" [ngbTooltip]="!showTitles?'Organization':''"
          container="body"></icon>
    <span [hidden]="!showTitles">{{ 'organization' | translate }}</span>
  </div>
  <div class="menu-item" [ngClass]="showTitles?'':'justify'" routerLink="/panel/setting/users"
       routerLinkActive="active">
    <icon icon="bi-person" size="xlarge" [ngbTooltip]="!showTitles?'Employees':''"
          container="body"></icon>
    <span [hidden]="!showTitles">{{ 'employees'  |translate }}</span>
  </div>
  <div class="menu-item" [ngClass]="showTitles?'':'justify'" routerLink="/panel/setting/teams"
       routerLinkActive="active">
    <icon icon="bi-people" size="xlarge" [ngbTooltip]="!showTitles?'Teams':''"
          container="body"></icon>
    <span [hidden]="!showTitles">{{ 'teams' | translate }}</span>
  </div>
  <div class="menu-item" [ngClass]="showTitles?'':'justify'" routerLink="/panel/setting/jobs"
       routerLinkActive="active">
    <icon icon="bi-briefcase" size="xlarge" [ngbTooltip]="!showTitles?'Jobs':''"
          container="body"></icon>
    <span [hidden]="!showTitles">{{ 'jobs' | translate }}</span>
  </div>
  <div class="menu-item" [ngClass]="showTitles?'':'justify'" routerLink="/panel/setting/locations"
       routerLinkActive="active">
    <icon icon="bi-pin-map" size="xlarge" [ngbTooltip]="!showTitles?'Locations':''"
          container="body"></icon>
    <span [hidden]="!showTitles">{{ 'locations' | translate }}</span>
  </div>
  <div class="menu-item" *ngIf="activePlan != 'Lifetime'" [ngClass]="showTitles?'':'justify'"
       routerLink="/panel/setting/billing" routerLinkActive="active">
    <icon icon="bi-wallet2" size="xlarge" [ngbTooltip]="!showTitles?'Billing':''"
          container="body"></icon>
    <span [hidden]="!showTitles">{{ 'billing'  |translate }}</span>
  </div>
  <div class="menu-item" *ngIf="activePlan != 'Lifetime'" [ngClass]="showTitles?'':'justify'"
       routerLink="/panel/setting/invoices" routerLinkActive="active">
    <icon icon="bi-receipt" size="xlarge" [ngbTooltip]="!showTitles?'Invoices':''"
          container="body"></icon>
    <span [hidden]="!showTitles">{{ 'INVOICES' | translate }} </span>
  </div>
  <div class="menu-item" *ngIf="activePlan == 'Lifetime'" [ngClass]="showTitles?'':'justify'"
       routerLink="/panel/setting/license" routerLinkActive="active">
    <icon icon="bi-receipt" size="xlarge" [ngbTooltip]="!showTitles?'License':''"
          container="body"></icon>
    <span [hidden]="!showTitles">{{ 'LICENSE' | translate }} </span>
  </div>

</div>
